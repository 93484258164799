export default class DefaultForm {
  static formDefault() {
    ////console.log('store: ', store)
    return {
      notPrintToPdf: false,
      idComplaintsExternal: null,
      isBussines: "",
      isPaterno: null,
      isMaterno: null,
      firstName: null,
      email: "",
      isTelefono: "",
      isTipoBien: "",
      isTipoDocumentoCliente: "",
      isMonto: null,
      isIdPremises: "",
      isAltPremises: "",
      isTipoReclamoQueja: null,
      isTipoReclamacion: null,
      isOtroTipoReclamo: "",
      isPedido: null,
      isDescripcionReclamo: null,
      isDepartamento: null,
      isProvincia: null,
      isDistrito: null,
      idDireccion: null,
      isAltProductoServicio: null,
      typeNotification: null,
    };
  }
}
