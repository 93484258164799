import Vue from 'vue'
import VeeValidate, { Validator } from 'vee-validate'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale'
import esEUI from 'element-ui/lib/locale/lang/es'
import es from 'vee-validate/dist/locale/es'
import App from './App.vue'
locale.use(esEUI)

import {
  Dialog,
  Input,
  Checkbox,
  CheckboxGroup,
  Select,
  Option,
  Button,
  Form,
  FormItem,
  Row,
  Col,
  Container,
  Main,
} from 'element-ui'

Vue.use(Dialog)
Vue.use(Input)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Select)
Vue.use(Option)
Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Row)
Vue.use(Col)
Vue.use(Container)
Vue.use(Main)

//Vue.use(ElementUI, )

Vue.use(VeeValidate, {
  fieldsBagName: 'veeFields',
  mode: 'eager',
  inject: false,
  events: 'change',
})
Validator.localize('es', es)

Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: (h) => h(App),
})
