<template>
  <div class="mainContainer">
    <el-container class="centerContainer">
      <el-main>
        <el-col :sm="18" :xs="18" :offset="3">
          <el-row>
            <el-col :xs="24" :md="4">
              <div class="">
                <img
                  width="120px"
                  src="https://grupo-cayman.com/wp-content/uploads/2020/08/i1.jpg"
                />
              </div>
            </el-col>
            <el-col :xs="24" :md="20">
              <div class="">
                <h5>
                  <p style="text-align: justify">
                    Conforme a lo establecido en el Código de Protección y
                    Defensa del Consumidor esta institución cuenta con un Libro
                    de Reclamaciones a tu disposición.
                  </p>
                </h5>
              </div>
            </el-col>
          </el-row>
        </el-col>
        <el-row>
          <el-col :span="24">
            <h4>1.IDENTIFICACIÓN DE LA EMPRESA</h4>
          </el-col>
        </el-row>
        <el-row>
          <ValidationObserver>
            <el-form ref="form" slot-scope="{ validate }">
              <el-row>
                <el-col :xs="24" :sm="8">
                  <ValidationProvider rules="required" name="Empresa">
                    <el-form-item
                      slot-scope="{ errors }"
                      :error="errors[0]"
                      label="Empresa *"
                    >
                      <el-select v-model="ruleForm.isBussines">
                        <el-option
                          v-for="item in listBussines"
                          :key="item.id"
                          :label="item.description"
                          :value="item.id"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </ValidationProvider>
                </el-col>
                <el-col :xs="24" :sm="8">
                  <el-form-item label="Ruc">
                    <ValidationProvider rules="" name="Ruc">
                      <el-input
                        placeholder="Ruc de la Empresa"
                        v-model="isNumeroDocumento"
                        :disabled="true"
                      >
                      </el-input>
                    </ValidationProvider>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="8">
                  <el-form-item label="Dirección">
                    <ValidationProvider rules="" name="Dirección">
                      <el-input
                        placeholder="Dirección de la Empresa"
                        v-model="isDireccion"
                        :disabled="true"
                      >
                      </el-input>
                    </ValidationProvider>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <h4>2.IDENTIFICACIÓN DEL CONSUMIDOR RECLAMANTE</h4>
                  <el-col :xs="24" :sm="8">
                    <ValidationProvider rules="required" name="Nombre">
                      <el-form-item
                        error-messages="mensaje Error"
                        slot-scope="{ errors }"
                        :error="errors[0]"
                        label="Nombre *"
                      >
                        <el-input v-model="ruleForm.firstName" />
                      </el-form-item>
                    </ValidationProvider>
                  </el-col>
                  <el-col :xs="24" :sm="8">
                    <ValidationProvider
                      rules="required"
                      name="Apellido Paterno"
                    >
                      <el-form-item
                        slot-scope="{ errors }"
                        :error="errors[0]"
                        label="Apellido Paterno *"
                      >
                        <el-input v-model="ruleForm.isPaterno"></el-input>
                      </el-form-item>
                    </ValidationProvider>
                  </el-col>
                  <el-col :xs="24" :sm="8">
                    <ValidationProvider
                      rules="required"
                      name="Apellido Materno"
                    >
                      <el-form-item
                        slot-scope="{ errors }"
                        :error="errors[0]"
                        label="Apellido Materno *"
                      >
                        <el-input v-model="ruleForm.isMaterno"></el-input>
                      </el-form-item>
                    </ValidationProvider>
                  </el-col>
                  <el-col :xs="24" :sm="6">
                    <ValidationProvider rules="required" name="Departamento">
                      <el-form-item
                        slot-scope="{ errors }"
                        :error="errors[0]"
                        label="Departamento *"
                      >
                        <el-select v-model="ruleForm.isDepartamento">
                          <el-option
                            v-for="item in isListDepartamento"
                            :key="item.idDepa"
                            :label="item.descripcion"
                            :value="item.idDepa"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </ValidationProvider>
                  </el-col>
                  <el-col :xs="24" :sm="6">
                    <ValidationProvider rules="required" name="Provincia">
                      <el-form-item
                        slot-scope="{ errors }"
                        :error="errors[0]"
                        label="Provincia *"
                      >
                        <el-select v-model="ruleForm.isProvincia">
                          <el-option
                            v-for="item in isListProvincia"
                            :key="item.idProv"
                            :label="item.descripcion"
                            :value="item.idProv"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </ValidationProvider>
                  </el-col>
                  <el-col :xs="24" :sm="6">
                    <ValidationProvider rules="required" name="Distrito">
                      <el-form-item
                        slot-scope="{ errors }"
                        :error="errors[0]"
                        label="Distrito *"
                      >
                        <el-select v-model="ruleForm.isDistrito">
                          <el-option
                            v-for="item in isListDistrito"
                            :key="item.idDist"
                            :label="item.descripcion"
                            :value="item.idDist"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </ValidationProvider>
                  </el-col>

                  <el-col :xs="24" :sm="6">
                    <ValidationProvider rules="required" name="Dirección">
                      <el-form-item
                        slot-scope="{ errors }"
                        :error="errors[0]"
                        label="Dirección *"
                      >
                        <el-input v-model="ruleForm.idDireccion"></el-input>
                      </el-form-item>
                    </ValidationProvider>
                  </el-col>
                  <el-col :xs="24" :sm="6">
                    <ValidationProvider rules="required" name="Tipo Documento">
                      <el-form-item
                        slot-scope="{ errors }"
                        :error="errors[0]"
                        label="Tipo Documento *"
                      >
                        <el-select v-model="ruleForm.isTipoDocumentoCliente">
                          <el-option
                            v-for="item in itemsDocumento"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </ValidationProvider>
                  </el-col>
                  <el-col
                    v-if="ruleForm.isTipoDocumentoCliente == 2"
                    :xs="24"
                    :sm="6"
                  >
                    <ValidationProvider
                      rules="required"
                      name="Número Documento"
                    >
                      <el-form-item
                        slot-scope="{ errors }"
                        :error="errors[0]"
                        label="Número Documento *"
                      >
                        <el-input
                          v-model="ruleForm.isNumeroDocumentoCliente"
                          :maxlength="maxNumberDocument"
                          show-word-limit
                        ></el-input>
                      </el-form-item>
                    </ValidationProvider>
                  </el-col>
                  <el-col v-else :xs="24" :sm="6">
                    <ValidationProvider
                      rules="required"
                      name="Número Documento"
                    >
                      <el-form-item
                        slot-scope="{ errors }"
                        :error="errors[0]"
                        label="Número Documento *"
                      >
                        <el-input
                          onkeypress="return /[\d]/i.test(event.key)"
                          v-model="ruleForm.isNumeroDocumentoCliente"
                          :maxlength="maxNumberDocument"
                          show-word-limit
                        ></el-input>
                      </el-form-item>
                    </ValidationProvider>
                  </el-col>
                  <el-col :xs="24" :sm="6">
                    <ValidationProvider rules="" name="Telefono / Celular">
                      <el-form-item
                        slot-scope="{ errors }"
                        :error="errors[0]"
                        label="Telefono / Celular"
                      >
                        <el-input
                          onkeypress="return /[\d]/i.test(event.key)"
                          maxlength="9"
                          minlength="9"
                          show-word-limit
                          v-model="ruleForm.isTelefono"
                        ></el-input>
                      </el-form-item>
                    </ValidationProvider>
                  </el-col>
                  <el-col :xs="24" :sm="6">
                    <ValidationProvider rules="email" name="Email">
                      <el-form-item
                        slot-scope="{ errors }"
                        :error="errors[0]"
                        label="Correo Electrónico"
                      >
                        <el-input v-model="ruleForm.email"></el-input>
                      </el-form-item>
                    </ValidationProvider>
                  </el-col>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <h4>3. IDENTIFICACIÓN DEL BIEN ADQUIRIDO</h4>
                  <el-col :xs="24" :sm="4">
                    <ValidationProvider rules="required" name="Tipo Bien">
                      <el-form-item
                        slot-scope="{ errors }"
                        :error="errors[0]"
                        label="Tipo Bien *"
                      >
                        <el-select v-model="ruleForm.isTipoBien">
                          <el-option
                            v-for="item in tipoBien"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </ValidationProvider>
                  </el-col>

                  <el-col :xs="24" :sm="10">
                    <ValidationProvider
                      rules="required"
                      name="Producto/Servicio"
                    >
                      <el-form-item
                        slot-scope="{ errors }"
                        :error="errors[0]"
                        label="Producto/Servicios *"
                      >
                        <el-input v-model="ruleForm.isAltProductoServicio" />
                      </el-form-item>
                    </ValidationProvider>
                  </el-col>
                  <el-col :xs="24" :sm="4">
                    <el-form-item label="Monto (Soles)">
                      <el-input
                        placeholder="S/.100.00"
                        v-model="ruleForm.isMonto"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="6">
                    <ValidationProvider rules="required" name="Sede">
                      <el-form-item
                        slot-scope="{ errors }"
                        :error="errors[0]"
                        label="Sede *"
                      >
                        <el-select v-model="ruleForm.isIdPremises">
                          <el-option
                            v-for="item in isListPremises"
                            :key="item.id"
                            :label="item.description"
                            :value="item.id"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </ValidationProvider>
                  </el-col>
                  <el-col v-if="addLocal" :span="24">
                    <ValidationProvider rules="required" name="Otra Sede">
                      <el-form-item slot-scope="{ errors }" :error="errors[0]">
                        <el-input
                          placeholder="Otra Sede"
                          v-model="ruleForm.isAltPremises"
                        ></el-input>
                      </el-form-item>
                    </ValidationProvider>
                  </el-col>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <h4>4. DETALLE DE RECLAMACIÓN</h4>
                  <el-col :xs="24" :sm="12">
                    <ValidationProvider
                      rules="required"
                      name="Tipo de Reclamación"
                    >
                      <el-form-item
                        slot-scope="{ errors }"
                        :error="errors[0]"
                        label="Tipo de Reclamación *"
                      >
                        <el-select v-model="ruleForm.isTipoReclamacion">
                          <el-option
                            v-for="item in tipoReclamo"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </ValidationProvider>
                  </el-col>
                  <el-col :xs="24" :sm="12">
                    <ValidationProvider
                      rules="required"
                      name="Tipo de Reclamo o Queja"
                    >
                      <el-form-item
                        slot-scope="{ errors }"
                        :error="errors[0]"
                        label="Tipo de Reclamo o Queja *"
                      >
                        <el-select v-model="ruleForm.isTipoReclamoQueja">
                          <el-option
                            v-for="item in tipoDetalleReclamo"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </ValidationProvider>
                  </el-col>
                  <el-col v-if="ruleForm.isTipoReclamoQueja == 3" :span="24">
                    <ValidationProvider
                      rules="required"
                      name="Otro Tipo de Reclamo o Queja"
                    >
                      <el-form-item
                        slot-scope="{ errors }"
                        :error="errors[0]"
                        label="Especificar Otro Tipo"
                      >
                        <el-input
                          v-model="ruleForm.isOtroTipoReclamo"
                        ></el-input>
                      </el-form-item>
                    </ValidationProvider>
                  </el-col>
                  <el-col :span="24">
                    <ValidationProvider rules="required" name="Descripción">
                      <el-form-item
                        slot-scope="{ errors }"
                        :error="errors[0]"
                        label="Descripción"
                      >
                        <el-input
                          type="textarea"
                          autosize
                          label="Descripcion del Reclamo *"
                          v-model="ruleForm.isDescripcionReclamo"
                        >
                        </el-input>
                      </el-form-item>
                    </ValidationProvider>
                  </el-col>
                  <el-col :span="24">
                    <ValidationProvider rules="required" name="Pedido">
                      <el-form-item
                        slot-scope="{ errors }"
                        :error="errors[0]"
                        label="Pedido *"
                      >
                        <el-input
                          type="textarea"
                          autosize
                          v-model="ruleForm.isPedido"
                        >
                        </el-input>
                      </el-form-item>
                    </ValidationProvider>
                  </el-col>

                  <el-col :xs="24" :sm="24">
                    <ValidationProvider
                      rules="required"
                      name="Medio Notificación"
                    >
                      <el-form-item
                        slot-scope="{ errors }"
                        :error="errors[0]"
                        label="Indique el medio por el que Ud. desea se le notifique la respuesta a su reclamo o requerimiento *"
                      >
                        <el-select v-model="ruleForm.typeNotification">
                          <el-option
                            v-for="item in listTypeNoti"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </ValidationProvider>
                  </el-col>
                  <!-- <el-col :span="24">
                    <p
                      style="
                        text-align: justify;
                        font-size: 14px;
                        padding: 24px;
                        border-radius: 20px 20px;
                        background: #fafafa;
                      "
                    >
                      NOTA: La respuesta a la presente queja o reclamo será
                      brindada mediante comunicación electrónica enviada a la
                      dirección que usted ha consignado en la presente Hoja de
                      Reclamación.
                      <br />
                      En caso de que usted desee que la respuesta le sea enviada
                      a su domicilio deberá expresar ello en el detalle del
                      reclamo o queja.
                    </p>
                  </el-col> -->
                  <el-col :span="24">
                    <CheckboxesWithValidation
                      rules="required"
                      name="ConfirmarFirst"
                      v-model="checkedFirst"
                    >
                      <el-checkbox label="Declaracion">
                        <p class="declaration">
                          Declaro haber leido y acepto <span> </span>
                          la politica de privacidad.
                        </p>
                      </el-checkbox>
                    </CheckboxesWithValidation>
                    <CheckboxesWithValidation
                      rules="required"
                      name="ConfirmarSecond"
                      v-model="checkedSecond"
                    >
                      <el-checkbox label="Declaracion">
                        <p class="declaration">
                          Declaro que los datos consignados <span> </span> son
                          correctos y fiel expresion de la <span> </span> verdad
                        </p>
                      </el-checkbox>
                    </CheckboxesWithValidation>
                  </el-col>
                  <el-col :span="24" class="mainButton">
                    <el-button
                      class="myButtonSend"
                      :loading="loading"
                      @click="validate().then(submitForm)"
                      >ENVIAR</el-button
                    >
                  </el-col>
                </el-col>
              </el-row>
            </el-form>
          </ValidationObserver>
        </el-row>
      </el-main>
      <el-dialog
        title="Se ha registrado su solicitud exitosamente"
        :show-close="false"
        :visible.sync="centerDialogVisible"
        custom-class=""
        :before-close="handleClose"
        width="300px"
        center
      >
        <span center>Su número de ticket es LRV-{{ messageResponse }}</span>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" @click="formCheck">Finalizar</el-button>
        </span>
      </el-dialog>
    </el-container>
  </div>
</template>

<script>
import axios from "axios";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import DefaultForm from "../src/utils/defaultForm";
import CheckboxesWithValidation from "./inputs/CheckboxesWithValidation";
const { VUE_APP_BASIC_USER, VUE_APP_BASIC_PASS } = process.env;

export default {
  $_veeValidate: {
    validator: "new",
  },
  name: "app",
  components: {
    ValidationObserver,
    ValidationProvider,
    CheckboxesWithValidation,
  },
  data: () => ({
    validator: null,
    loading: false,
    itemsDocumento: [
      { value: 1, label: "DNI" },
      { value: 2, label: "CARNET DE EXTRANJERIA" },
      { value: 3, label: "RUC" },
      { value: 4, label: "PASAPORTE" },
    ],
    tipoBien: [
      { value: 1, label: "PRODUCTO" },
      { value: 2, label: "SERVICIO" },
    ],
    tipoReclamo: [
      { value: 1, label: "RECLAMO" },
      { value: 2, label: "QUEJA" },
    ],
    tipoDetalleReclamo: [
      { value: 1, label: "PRODUCTO" },
      { value: 2, label: "SERVICIO" },
      { value: 3, label: "OTRO" },
    ],
    listTypeNoti: [
      { value: "EMAIL", label: "Correo Electrónico" },
      { value: "DOMICILIO", label: "Carta en su Domicilio" },
    ],

    isNumeroDocumento: null,
    isDireccion: null,
    ruleForm: DefaultForm.formDefault(),
    centerDialogVisible: false,
    messageResponse: "",
    addLocal: false,
    addTypeReclamoQueja: false,
    isListProducts: [],
    isListPremises: [],
    listBussines: [],
    checkedFirst: [],
    checkedSecond: [],
    isListDepartamento: [],
    isListDistrito: [],
    isListProvincia: [],
  }),
  watch: {
    relativeTipoBien() {
      this.isProducto = null;
    },
    relativeTipoDocumento(v) {
      console.log("v", v);
      this.isNumeroDocumentoCliente = null;
    },
    isEmail(v) {
      console.log("v", v);
      if (v) {
        this.ruleForm.typeNotification = "EMAIL";
      } else {
        this.ruleForm.typeNotification = "DOMICILIO";
      }
    },
    async relativeDistrito(v) {
      if (v) {
        console.log("v", v);
        this.ruleForm.isDistrito = null;
        const { data } = await axios({
          url: `${process.env.VUE_APP_CRM_API}/private/ubigeo/provincias/${v}/distritos`,
          auth: { username: VUE_APP_BASIC_USER, password: VUE_APP_BASIC_PASS },

          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "GET",
          authorization: process.env.TOKEN_CAYMAN_UBIGEO,
        });
        console.log("data.payload", data.payload);
        this.isListDistrito = data.payload;
      }
    },
    async relativeProvincia(v) {
      const { data } = await axios({
        url: `${process.env.VUE_APP_CRM_API}/private/ubigeo/departamentos/${v}/provincias`,
        auth: { username: VUE_APP_BASIC_USER, password: VUE_APP_BASIC_PASS },

        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "GET",
        authorization: process.env.TOKEN_CAYMAN_UBIGEO,
      });
      console.log("data.payload", data.payload);
      this.isListProvincia = data.payload;
    },
    relativeBussines(v) {
      if (v) {
        const found = this.listBussines.find((x) => x.id == v);
        console.log("found", found);
        this.isNumeroDocumento = found.numberDocument;
        this.isDireccion = found.address;
      }
    },
    relativePremises(v) {
      if (v) {
        v == 16 ? (this.addLocal = true) : (this.addLocal = false);
      }
    },
   
  },
  methods: {
    handleClose() {},
    formCheck() {
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          this.centerDialogVisible = false;
          location.reload();
          this.isNumeroDocumento = null;
          this.isDireccion = null;
          this.ruleForm = DefaultForm.formDefault();
        }
      });
    },
    async submitForm() {
      this.loading = true;

      console.log("open");
      const response = await axios({
        url: `${process.env.VUE_APP_MS_BACKEND}/complaints`,
        auth: { username: VUE_APP_BASIC_USER, password: VUE_APP_BASIC_PASS },

        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        responseType: "blob",
        method: "POST",
        data: {
          ...this.ruleForm,
          labelPremises: this.isListPremises.find(
            (x) => x.id == this.ruleForm.isBussines
          ).description,
          labelDepartamento: this.isListDepartamento.find(
            (x) => x.idDepa == this.ruleForm.isDepartamento
          ).descripcion,
          labelDistrito: this.isListDistrito.find(
            (x) => x.idDist == this.ruleForm.isDistrito
          ).descripcion,
          labelProvincia: this.isListProvincia.find(
            (x) => x.idProv == this.ruleForm.isProvincia
          ).descripcion,
          labelBussines: this.listBussines.find(
            (x) => x.id == this.ruleForm.isBussines
          ).description,
          labelSede: this.isListPremises.find(
            (x) => x.id == this.ruleForm.isIdPremises
          ).description,
        },
      });
      console.log("intento", response.data.headers);
      console.log("response", response.headers);
      console.log("response", response.headers.someparams);
      if (response.status == 200) {
        const message = `${
          JSON.parse(response.headers.someparams).idComplaints
        }`.padStart(6, "0");
        console.log("message", message);
        this.messageResponse = message;
        this.centerDialogVisible = true;
      }
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement("a");
      fileLink.href = fileURL;
      // fileLink.setAttribute("download", "LR_DNI_RECLAMACION_TIMESTOMP.pdf");
      fileLink.setAttribute(
        "download",
        `LR_${this.ruleForm.isNumeroDocumentoCliente}_${
          this.messageResponse
        }_${Date.now()}.pdf`
      );
      document.body.appendChild(fileLink);
      console.log("as----c", response);
      fileLink.click();
      const idComplaints = JSON.parse(response.headers.someparams).idComplaints;
      console.log("idComplaints", idComplaints);

      // this.$nextTick(() => {
      //   this.$validator.reset();
      // });
      this.loading = false;

      // this.$form.clean();
      // this.errors.reset();
    },
  },
  computed: {
    isEmail() {
      return this.ruleForm.email;
    },
    typeNotification() {
      return this.ruleForm.typeNotification;
    },
    relativeBussines() {
      return this.ruleForm.isBussines;
    },
    relativeDistrito() {
      return this.ruleForm.isProvincia;
    },
    relativeProvincia() {
      return this.ruleForm.isDepartamento;
    },
  
    relativePremises() {
      return this.ruleForm.isIdPremises;
    },
    relativeTipoBien() {
      return this.ruleForm.isTipoBien;
    },
    relativeTipoDocumento() {
      return this.ruleForm.isTipoDocumentoCliente;
    },

    maxNumberDocument() {
      let maxLength = null;
      switch (this.ruleForm.isTipoDocumentoCliente) {
        case 1:
          maxLength = "8";
          break;
        case 3:
          maxLength = "11";
          break;
        default:
          break;
      }
      return maxLength;
    },
  },
  async created() {
    const release = await Promise.all([
      axios({
        url: `${process.env.VUE_APP_CRM_API}/private/ubigeo/departamentos`,
        method: "GET",

        auth: { username: VUE_APP_BASIC_USER, password: VUE_APP_BASIC_PASS },

        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      axios({
        url: `${process.env.VUE_APP_MS_BACKEND}/bussines`,
        method: "GET",
        auth: { username: VUE_APP_BASIC_USER, password: VUE_APP_BASIC_PASS },

        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
      axios({
        url: `${process.env.VUE_APP_MS_BACKEND}/premises`,
        method: "GET",
        auth: { username: VUE_APP_BASIC_USER, password: VUE_APP_BASIC_PASS },

        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }),
    ]);

    this.isListPremises = release[2].data;
    this.isListDepartamento = release[0].data.payload;
    this.listBussines = release[1].data;
  },
};
</script>
<style scoped>
* {
  /* font-family: "Poppins", sans-serif; */
  font-family: "Poppins", sans-serif;
  /* azelli   "Titillium Web", sans-serif;*/
  /* zongsheng "Roboto Web", sans-serif; */
}
.mainContainer {
  display: flex;
  justify-content: center;
  justify-items: center;

  background: #fafafa;
}
.centerContainer {
  max-width: 1024px;
  padding: 24px 0;
  background: #fefefe;
}

.mainButton {
  display: flex;
  justify-content: center;
  justify-items: center;
}

.el-main {
  max-width: 1200px;
  padding: 16px;
  background-color: white;
}
@media (max-width: 600px) {
  .centerContainer {
    max-width: 1024px;
    padding: 16px 0;
    background: #fefefe;
  }
  .el-main {
    padding: 8px;
  }
}
h5,
h4 {
  text-align: center;
  font-size: 15px !important;
}

.el-select,
.el-input-number {
  width: 100%;
}

.imageCenter img {
  width: 100%;
  height: auto;
}

.el-form-item {
  padding: 0 5px;
}

/* .box-card {
  border: none;
}
.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: none;
  border: red;
} */

/* .el-aside {
  background-color: #d3dce6;
  color: #333;
  line-height: 200px;
}

.el-main {
  background-color: #e9eef3;
  color: #333;
  text-align: center;
} */
@media only screen and (max-width: 600px) {
  .declaration span {
    font-size: 0;
    display: block;
  }
}

.myButtonSend {
  display: flex;
  background-color: #ff0000;
  color: #fff;
  width: 100%;
  max-width: 150px;
  justify-content: center;
}
</style>
